<template>
    <div id="verifyEmail">

        <div class="card-body p-4">
            <h5 class="mb-1 text-center">{{$t('verifyEmail.confermaIndirizzoEmail')}}</h5>
            <p> {{$t('verifyEmail.descrizione')}} </p>
            <form class="form-horizontal" @submit.prevent="verifyEmail()">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-custom mb-2">
                            <input type="text" class="form-control" id="username" v-model.trim="$v.form.email.$model" required>
                            <label for="username">{{$t('verifyEmail.form.email.label')}}</label>
                            <div class="error pl-vertical-align-input" v-if="!$v.form.email.email">{{$t('verifyEmail.form.email.erroreFormato')}}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="gdpr-checkbox" required>
                                  <label style="font-size:11px" class="custom-control-label pt-1" for="gdpr-checkbox">{{$t('verifyEmail.hoLettoECompreso')}} <a href="#" data-toggle="modal" data-target="#myModal" data-backdrop="static"> {{$t('components.registrazione.informativaPrivacy')}}</a></label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" v-model="form.flagIDUser" id="id-user-check" required>
                                    <label style="font-size:11px" class="custom-control-label pt-1" for="id-user-check">{{$t('verifyEmail.form.checkbox.autorizzoIlMioIDUtente.label')}} <a type="button" data-toggle="modal" data-target="#termsAndConditionsModal" class="text-primary">{{$t('verifyEmail.form.checkbox.autorizzoIlMioIDUtente.link')}}</a></label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="user-data-check" v-model="form.flagCommercial">
                                    <label style="font-size:11px" class="custom-control-label pt-1" for="user-data-check">{{$t('verifyEmail.form.checkbox.autorizzoIlTrattamentoEmail.label')}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <button class="btn btn-success btn-block waves-effect waves-light" type="submit">{{$t('verifyEmail.confirmButton')}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <!-- modali -->
        <div id="myModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalLabel">{{$t('verifyEmail.modale.gdpr.titolo')}}</h5>
                    </div>
                    <div id="modal-body" class="modal-body">
                        <p v-html="$t('verifyEmail.modale.gdpr.testo')"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>

        <div id="modalError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorLabel">{{$t('verifyEmail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-error-body" class="modal-body">
                        <h5 class="font-size-16">{{$t('verifyEmail.modale.errore.sottotitolo')}}</h5>
                        <p id="message-body"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>

        <!-- Modal overlay login -->
        <div id="modalOverlayLogin" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalOverlayLoginLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-transparent border-0">
                    <div id="modal-body-overlay-pause" class="modal-body">
                        <div class="w-100 text-center pb-4">
                            <img src="../assets/images/logo-concorsando-white.png">
                        </div>
                        <div class="w-100 text-center">
                            <h3 class="text-white" v-if="!isLogging">{{$t('components.socialLogin.modale.effettuaLaLoginConIlSocial')}}</h3>
                            <div v-else class="loader mx-auto"></div>
                        </div>
                        <div class="w-100 text-center" v-if="!isLogging">
                            <button type="button" class="btn btn-primary waves-effect waves-light" @click="hideOverlay(true)" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /Modal overlay login -->
        <!-- /modali -->
    </div>
</template>

<script>
    import 'bootstrap'
    import $ from 'jquery'
    import { email } from 'vuelidate/lib/validators'
    import { mapGetters, mapActions } from 'vuex'
    import { MODULES } from "../store/modules-enum";
    import { AUTH_ACTIONS } from "../store/auth/actions-enum";
    import { AUTH_GETTERS } from "../store/auth/getters-enum";
    import _ from "lodash";
    import {LoginStates} from "../flows/login/loginStates";
    import {loginTransition} from "../flows/login/loginFlow";
    import firebase from "firebase/app";

    export default {
        name: "VerifyEmail",
        data () {
            return {
                form: {
                    email: '',
                    flagIDUser: false,
                    flagCommercial: false
                },
                isLogging: false,
                nonce: undefined,
            }
        },
        computed: {
          ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.AUTHENTICATIONS])
        },
        mounted() {
            this.verifyPreFilledFields(this.$route.params)
        },
        validations: {
            form: {
                email: {
                    email
                }
            }
        },
        beforeDestroy() {
            this.hideOverlay();
        },
        methods: {
            ...mapActions(MODULES.AUTH, [AUTH_ACTIONS.SIGNUP]),
            verifyEmail () {
                const context = this
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    $('#modalOverlayLogin').modal({backdrop: 'static', keyboard: false})
                    this.$emit('is-components-ready', 'verifyEmail', false)
                    this.nonce = Date.now()
                    const nonce = this.nonce;
                    this.loginAppleIfNecessary().then(res => {
                        if (nonce < this.nonce) {
                            return;
                        }
                        let tokenSocial = this.authentications.tokenSocial
                        if (res) {
                            tokenSocial = res;
                        }
                        this.isLogging = true;
                        this.signup({
                            emailComunicazioni: this.form.email,
                            flag_privacy: (!this.form.flagCommercial) ? 1 : 2,
                            tokenSocial,
                            social: this.authentications.social
                        })
                            .then(() => {
                                window.fbq('trackCustom', 'web_signin')
                                firebase.analytics().logEvent('web_signin')
                                this.hideOverlay();
                                this.$emit('is-components-ready', 'verifyEmail', true)
                                loginTransition(LoginStates.VERIFY_EMAIL);
                            })
                            .catch((error) => {
                                this.hideOverlay();
                                context.$emit('is-components-ready', 'verifyEmail', true)
                                if (error.response !== undefined) {
                                    if (error.response.status === 409) {
                                        $('#modalError').modal('show')
                                        $('#message-body').html(context.$t('verifyEmail.modale.errore.utenteGiaEsistente'))
                                        return
                                    }
                                }
                                $('#modalError').modal('show')
                                $('#message-body').html(context.$t('verifyEmail.modale.errore.generale'))
                            })
                    })
                        .catch(e => {
                            let isNewLogin = _.get(e, "error") === "user_trigger_new_signin_flow";
                            if (isNewLogin) {
                                // Se l'utente ha triggerato un nuovo login non devo fare niente (ma la promessa deve andare comunque in errore)
                                throw e;
                            }
                            this.hideOverlay();
                            let isPopupClosedError = _.get(e, "error") === "popup_closed_by_user"
                            let isUserCancelError = _.get(e, "error") === "user_cancelled_authorize"
                            if (!isPopupClosedError && !isUserCancelError) {
                                console.error(e);
                                $('#modalError').modal('show')
                                $('#message-body').html(this.$t('components.socialLogin.modale.errore.generale'))
                            }
                            throw e;
                        })
                }
            },
            async loginAppleIfNecessary() {
                if (this.authentications.social.toLowerCase() === "apple") {
                    // eslint-disable-next-line no-undef
                    let res = await AppleID.auth.signIn()
                    return res.authorization.code;
                }
            },
            verifyPreFilledFields (params) {
                if (params.flagPrivacy >= 1) {
                    this.form.flagIDUser = true
                }
                if (params.flagPrivacy === 2) {
                    this.form.flagCommercial = true
                }
                if (params.emailComunicazioni !== '' || params.emailComunicazioni !== null) {
                    this.form.email = params.emailComunicazioni
                }
            },
            hideOverlay (updateNonce = false) {
                if (updateNonce) {
                    this.nonce = Date.now();
                }
                $('#modalOverlayLogin').modal('hide')
                this.isLogging = false
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .pl-vertical-align-input {
            padding-left: 14px;
        }

        #modalOverlayLogin {
            background-color: #00000082;
        }
    }
</style>
